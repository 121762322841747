import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import OpenSum from './OpenSum/OpenSum';
import OpenDeals from '../../OpenComponents/OpenDeals/OpenDeals';
import OpenDevice from '../../OpenComponents/OpenDevice/OpenDevice';
import OpenReceipt from './OpenReceipt/OpenReceipt';
import OpenRequisite from '../../OpenComponents/OpenRequisite/OpenRequisite';
import Transaction from '../../../../components/Table/Transaction/Transaction';
import OpenModal from './OpenModal/OpenModal';
import OpenChat from './OpenChat/OpenChat';
import Popup from 'reactjs-popup';
import './module.css';

export default function OpenDispute({ setPageLoading }) {
    const { id } = useParams();
    const [filteredOpenDispute, setFilteredOpenDispute] = useState({});
    const [disputeFiles, setDisputeFiles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showChatModal, setShowChatModal] = useState(false);

    const office_type = localStorage.getItem('office_type');

    useEffect(() => {
        const fetchData = async () => {

            setPageLoading(true);
            try {
                const [disputeResponse, filesResponse] = await Promise.all([
                    fetch(`/api/dispute/${id}`),
                    fetch(`/api/dispute-files?dispute_id=${id}`)
                ]);

                if (disputeResponse.ok && filesResponse.ok) {
                    const disputeData = await disputeResponse.json();
                    const filesData = await filesResponse.json();
                    setFilteredOpenDispute(disputeData);
                    setDisputeFiles(filesData);

                } else {
                    console.error('Ошибка при загрузке данных', disputeResponse.status, filesResponse.status);
                }
            } catch (error) {
                console.error('Ошибка:', error);
            } finally {
                setPageLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const transactionProps = filteredOpenDispute ? {
        text: !filteredOpenDispute.dispute?.status ? 'Открытый спор' : filteredOpenDispute.dispute?.reason_id === null ? 'Спор принят' : 'Спор не принят',
        icon: 'icon-park-outline:database-time',
        style: {
            color: !filteredOpenDispute.dispute?.status ? 'grey' : filteredOpenDispute.dispute?.reason_id === null ? '#67c44e' : 'red',
            backgroundColor: !filteredOpenDispute.dispute?.status ? '#efefef' : filteredOpenDispute.dispute?.reason_id === null ? 'rgba(165, 224, 131, 0.501)' : 'rgba(255, 0, 0, 0.17)'
        },
        eventTime: `ID сделки: ${filteredOpenDispute.payment?.payment?.id || ''}`
    } : {};

    const usdtProp = filteredOpenDispute ? filteredOpenDispute.payment?.payment?.amount?.usdt : null;
    const rubProp = filteredOpenDispute ? filteredOpenDispute.payment?.payment?.amount?.rub : null;

    console.log(filteredOpenDispute.payment?.payment?.status)
    return (
        <div className='openDispute'>
            <div className='headerData'>
                {filteredOpenDispute && (
                    <Transaction
                        text={transactionProps.text}
                        icon={transactionProps.icon}
                        style={transactionProps.style}
                        eventTime={transactionProps.eventTime}
                    />
                )}
                {filteredOpenDispute && (
                    <OpenSum
                        usdt={usdtProp}
                        rub={rubProp}
                    />
                )}
                {filteredOpenDispute
                    && filteredOpenDispute.payment?.payment?.status !== "AC" &&
                    office_type == 'support' && (
                        <div className="disputeHeaderButtons">
                            <button className="buttonDispute" onClick={() => { setShowModal(true) }}>
                                Разрешить
                            </button>
                            {filteredOpenDispute.payment?.payment?.status === "CF" && (
                                <button className="buttonDispute" onClick={() => { setShowChatModal(true) }}>
                                    Чат
                                </button>
                            )}
                        </div>
                    )}
                {filteredOpenDispute
                    && filteredOpenDispute.payment?.payment?.status === "SF" &&
                    office_type == 'trader' && (
                        <div className="disputeHeaderButtons">
                            <button className="buttonDispute" onClick={() => { setShowModal(true) }}>
                                Разрешить
                            </button>
                        </div>
                    )}
                <Popup open={showModal} onClose={() => { setShowModal(false) }}>
                    <OpenModal
                        dispute={filteredOpenDispute.dispute}
                        payment={filteredOpenDispute.payment}
                        setDispute={setFilteredOpenDispute}
                        close={setShowModal}
                    />
                </Popup>
                <Popup open={showChatModal} onClose={() => { setShowChatModal(false) }}>
                    <OpenChat
                        dispute={filteredOpenDispute.dispute}
                        payment={filteredOpenDispute.payment}
                        setDispute={setFilteredOpenDispute}
                        close={setShowChatModal}
                    />
                </Popup>
            </div>
            <hr />
            <div className="dataDispute">
                {disputeFiles && (
                    <OpenReceipt
                        props={disputeFiles}
                    />
                )}
                {filteredOpenDispute && (
                    <OpenDeals
                        props={filteredOpenDispute}
                    />
                )}
                {(
                    <OpenRequisite
                        props={filteredOpenDispute}
                    />
                )}
                {filteredOpenDispute && (
                    <OpenDevice
                        props={filteredOpenDispute}
                    />
                )}

            </div>
        </div>
    );
}
